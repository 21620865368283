div.employee-card {
  width: 100%;
  padding: 16px 0;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;


  div.img-circle {
    width: 56px;
    height: 56px;
    border-radius: 100px;
    overflow: hidden;
    display: block;
    margin-bottom: 8px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      background: #FAFAFA;
    }
  }

  span.employee-name {
    font-size: 16px;
    font-weight: 600;
  }

  span.job-title {
    font-size: 13px;
    font-weight: 500;
    color: #808080;
  }

  div.edit-button {

    i.material-icons {
      font-size: 14px;
    }
  }

}