.table-container {
    width: 100%;
    color: #212529;
    text-align: left;
    border-collapse: collapse;
    font-weight: 500;

    .limited-line {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;

        &-1 {
            -webkit-line-clamp: 1;
        }

        &-2 {
            -webkit-line-clamp: 2;
        }

        &-3 {
            -webkit-line-clamp: 3;
        }
    }

    thead {
        tr {
            height: 38px;
            background: transparent !important;
        }
    }

    .action-column {
        display: flex;
        justify-content: center;
        min-width: 150px !important;
        padding: 3px 8px;

        button {
            margin-right: 8px;
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06);

            &:nth-last-child(1) {
                margin: 0;
            }
        }
    }

    th.action-column {
        padding: 8px;
    }

    tr {
        border-bottom: 1px solid #efefef;
        align-items: center;

        &:hover {
            background: #f9f9f9;
            transition: all ease 0.25s;
        }

        &:nth-last-child(1) {
            border-bottom: 0;
        }

        th {
            padding: 0 8px;
            font-weight: 600;
            font-size: 13px;
            table-layout: fixed;
            color: #555;

            svg {
                width: 20px;
                height: 20px;
            }

            &:nth-child(1) {
                padding-left: 0;
            }

            &:nth-last-child(1) {
                padding-right: 0;
            }
        }

        td {
            font-size: 14px;
            padding: 4px 8px;
            box-sizing: border-box;
            min-height: 52px;
            table-layout: auto;
            align-items: center;
            min-width: 100px;
            border: 0;

            &:nth-child(1) {
                padding-left: 0;
            }

            &:nth-last-child(1) {
                padding-right: 0;
            }

            img.square-pic {
                width: 60px;
                height: 60px;
                object-fit: cover;
                border-radius: 5px;
            }
        }
    }
}
