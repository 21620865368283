div.input-group {
  position: relative;
  padding: 0;
  margin: 0 0 16px;
  background: #FFF;
  width: 100%;
  display: block;

  label {
    position: absolute;
    max-height: 32px;
    overflow: hidden;
    top: 0;
    left: 12px;
    color: #505050;
    display: block;
    font-weight: 600;
    font-size: 13px;
    line-height: 32px;
    margin: 0;
    padding: 0;
    transition: all ease 0.3s;

    .skeleton::before {
      content: '';
      display: block;
      height: 12px;
      margin: 12px 0 6px;
    }
  }

  input.form-item:read-only {
    color: #707070;
  }

  .form-item {
    min-height: 62px;
    max-width: 100%;
    font-family: "Inter", serif !important;
    text-align: left;
    font-weight: 500;
    color: #101010;
    background: transparent;
    position: relative;
    z-index: 100;
    width: 100%;
    font-size: 14px;
    line-height: 16px;
    padding: 32px 12px 16px 12px;
    box-sizing: border-box;
    border: 1px solid var(--primary-transparent);

    box-shadow: 0 0 8px rgba(0, 0, 0, 0.04);
    border-radius: 3px;
    outline: 0;
    resize: none;
    transition: all ease 0.3s;


    * {
      line-height: 16px;
    }
    

    .skeleton {
      content: ' ';
      height: 16px;
      display: block;
    }


    .search-input{
      font-family: "Inter", serif !important;
      border: 0;
      outline: 0;
      font-weight: 500;
      font-size: 14px;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      padding: 32px 12px 16px 12px;
      background: transparent;
    }

    .search-result{
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      padding: 32px 12px 16px 12px;
    }


    &::placeholder {
      color: #A0A0A0;
    }
  }

  .form-dropdown {
    position: absolute;
    z-index: 150;
    width: 100%;
    max-height: 180px;
    overflow-y: auto;
    padding: 16px 0;
    margin: 0;
    list-style-type: none;
    background: #FFF;
    box-shadow: 0 2px 16px rgba(0, 0, 0, 0.08);


    &:hover {
      visibility: visible
    }

    li {
      padding: 8px 16px;
      line-height: 24px;
      cursor: pointer;

      &:hover {
        background: #FAFAFA
      }

      &:focus {
        visibility: visible
      }
    }


    /* width */
    &::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #FAFAFA;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #606060;
    }
  }

  .input-info {
    p {
      font-size: 12px;
      font-weight: 500;
      text-align: center;
      padding-top: 12px;

      b {
        font-weight: 600;
      }
    }
  }
}

div.checkbox-group {

  div.check-item {
    display: flex;
    align-items: center;
    font-weight: 500;

    &.active {
      i.material-icons {
        color: var(--primary);
      }
    }
  }
}

;
