div.gallery-card {
  width: 100%;
  height: 140px;
  position: relative;
  margin-bottom: 16px;
  overflow: hidden;

  &:hover {
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.08);

    .card-overlay {
      opacity: 1
    }
  }

  img.gallery-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .card-overlay {
    opacity: 0;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 8px;
    background: #FFF;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: #FFF;
    transition: all ease 0.250s;

    .gallery-action {
      display: flex;
      align-items: center;
    }
  }
}

