.btn {
  font-family: "Inter", sans-serif !important;
  background-color: var(--white);
  color: var(--primary);
  font-size: 14px;
  font-weight: 500;
  border-radius: 5px !important;
  outline: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 1.6px !important;
  //box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);


  &-transition-effect {
    transition: all ease 500ms;
  }


  &.no-padding {
    padding: 0
  }


  &.full-width {
    width: 100%;
  }

  &.border {
    border: 1.6px solid !important;
    padding: 0 !important;
  }

  &-disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }


  i {
    font-size: 21px
  }


  &-sm>.button-container {
    height: 24px
  }

  &-md>.button-container {
    height: 32px;
  }

  &-lg>.button-container {
    height: 40px;
  }


  div.button-container {
    padding: 8px;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;


   // i.material-icons {
      //font-size: 18px;
   // }
  }


  .ripple {
    display: block;
    position: relative;
    overflow: hidden;
    transform: translate3d(0, 0, 0);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;

    &:after {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      pointer-events: none;
      background-image: radial-gradient(circle, #000 8.5%, transparent 1%);
      background-repeat: no-repeat;
      background-position: 50%;
      transform: scale(10, 10);
      opacity: 0;
      transition: transform 0.3s, opacity 0.3s;
    }

    &:active:after {
      transform: scale(0, 0);
      opacity: .3;
      transition: 0s;
    }

  }


  span {
    display: inline-block;
    
  }
}



@media only screen and (max-width: 1200px) {
  td.action-column>*>.button-container {
    display: inline-table;
  }
}