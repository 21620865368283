.content {
  display: block; /* Varsayılan olarak tüm ekran boyutlarında görünür */
  width: 90%;
  display: flex;
  justify-content: space-around;
}

.smallScreenOnly {
  display: none; /* Varsayılan olarak gizlenir */
}

@media (max-width: 768px) {
  .content {
    display: none; /* Küçük ekranlarda varsayılan içeriği gizler */
  }

  .smallScreenOnly {
    display: block; /* Küçük ekranlarda bu içeriği gösterir */
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
}
