.fw {
  &-400 {
    font-weight: 400 !important;
  }

  &-500 {
    font-weight: 500 !important;
  }

  &-600 {
    font-weight: 600 !important;
  }

  &-700 {
    font-weight: 700 !important;
  }
}


@import url('https://fonts.googleapis.com/css?family=Inter:300,400,500,600,700,800&display=swap&subset=latin-ext');

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap');

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v50/flUhRq6tzZclQEJ-Vdg-IuiaDsNcIhQ8tQ.woff2) format('woff2');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}



//@font-face {
//  font-family: 'Inter';
//  font-style:  normal;
//  font-weight: 400;
//  font-display: swap;
//  src: url("../../fonts/Inter-Regular.woff2?v=3.11") format("woff2"),
//  url("../../fonts/Inter-Regular.woff?v=3.11") format("woff");
//}
//@font-face {
//  font-family: 'Inter';
//  font-style:  italic;
//  font-weight: 400;
//  font-display: swap;
//  src: url("../../fonts/Inter-Italic.woff2?v=3.11") format("woff2"),
//  url("../../fonts/Inter-Italic.woff?v=3.11") format("woff");
//}
//
//@font-face {
//  font-family: 'Inter';
//  font-style:  normal;
//  font-weight: 500;
//  font-display: swap;
//  src: url("../../fonts/Inter-Medium.woff2?v=3.11") format("woff2"),
//  url("../../fonts/Inter-Medium.woff?v=3.11") format("woff");
//}
//@font-face {
//  font-family: 'Inter';
//  font-style:  italic;
//  font-weight: 500;
//  font-display: swap;
//  src: url("../../fonts/Inter-MediumItalic.woff2?v=3.11") format("woff2"),
//  url("../../fonts/Inter-MediumItalic.woff?v=3.11") format("woff");
//}
//
//@font-face {
//  font-family: 'Inter';
//  font-style:  normal;
//  font-weight: 600;
//  font-display: swap;
//  src: url("../../fonts/Inter-SemiBold.woff2?v=3.11") format("woff2"),
//  url("../../fonts/Inter-SemiBold.woff?v=3.11") format("woff");
//}
//@font-face {
//  font-family: 'Inter';
//  font-style:  italic;
//  font-weight: 600;
//  font-display: swap;
//  src: url("../../fonts/Inter-SemiBoldItalic.woff2?v=3.11") format("woff2"),
//  url("../../fonts/Inter-SemiBoldItalic.woff?v=3.11") format("woff");
//}
//
//@font-face {
//  font-family: 'Inter';
//  font-style:  normal;
//  font-weight: 700;
//  font-display: swap;
//  src: url("../../fonts/Inter-Bold.woff2?v=3.11") format("woff2"),
//  url("../../fonts/Inter-Bold.woff?v=3.11") format("woff");
//}
//@font-face {
//  font-family: 'Inter';
//  font-style:  italic;
//  font-weight: 700;
//  font-display: swap;
//  src: url("../../fonts/Inter-BoldItalic.woff2?v=3.11") format("woff2"),
//  url("../../fonts/Inter-BoldItalic.woff?v=3.11") format("woff");
//}
//
//@font-face {
//  font-family: 'Inter';
//  font-style:  normal;
//  font-weight: 800;
//  font-display: swap;
//  src: url("../../fonts/Inter-ExtraBold.woff2?v=3.11") format("woff2"),
//  url("../../fonts/Inter-ExtraBold.woff?v=3.11") format("woff");
//}
//@font-face {
//  font-family: 'Inter';
//  font-style:  italic;
//  font-weight: 800;
//  font-display: swap;
//  src: url("../../fonts/Inter-ExtraBoldItalic.woff2?v=3.11") format("woff2"),
//  url("../../fonts/Inter-ExtraBoldItalic.woff?v=3.11") format("woff");
//}
//
//@font-face {
//  font-family: 'Inter';
//  font-style:  normal;
//  font-weight: 900;
//  font-display: swap;
//  src: url("../../fonts/Inter-Black.woff2?v=3.11") format("woff2"),
//  url("../../fonts/Inter-Black.woff?v=3.11") format("woff");
//}
//@font-face {
//  font-family: 'Inter';
//  font-style:  italic;
//  font-weight: 900;
//  font-display: swap;
//  src: url("../../fonts/Inter-BlackItalic.woff2?v=3.11") format("woff2"),
//  url("../../fonts/Inter-BlackItalic.woff?v=3.11") format("woff");
//}



@font-face {
  font-family: "Ionicons";
  src: url("https://code.ionicframework.com/ionicons/2.0.1/fonts/ionicons.eot?v=2.0.1");
  src: url("https://code.ionicframework.com/ionicons/2.0.1/fonts/ionicons.eot?v=2.0.1#iefix") format("embedded-opentype"), url("https://code.ionicframework.com/ionicons/2.0.1/fonts/ionicons.ttf?v=2.0.1") format("truetype"), url("https://code.ionicframework.com/ionicons/2.0.1/fonts/ionicons.woff?v=2.0.1") format("woff"), url("https://code.ionicframework.com/ionicons/2.0.1/fonts/ionicons.svg?v=2.0.1#Ionicons") format("svg");
  font-weight: normal;
  font-style: normal;
}
