.single-pricing {
  -webkit-box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.08);
  padding: 24px 20px 16px;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  background-color: #fff;
  transition: all ease-in-out 0.25s;

  &:hover {
    -webkit-box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.12);

    .leading-image {
      transform: scale(1.2);
    }
  }
}

.single-pricing .pricing-header .sub-title {
  font-size: 18px;
  font-weight: 600;
  line-height: 1;
  color: #121212;
  position: relative;
}

.single-pricing .leading-image {
  position: absolute;
  top: -30px;
  right: -130px;
  width: 280px;
  transform: scale(1.1);
  transition: all ease 0.25s;
}

.single-pricing .pricing-header {
  position: relative;
  z-index: 500;
}

.single-pricing .pricing-header .price {
  display: block;
  font-size: 23px;
  letter-spacing: -1px;
  font-weight: 600;
  line-height: 1;
  color: #121212;
  margin-bottom: 0;
}

.single-pricing .pricing-header .time {
  display: block;
  font-size: 16px;
  letter-spacing: -1px;
  font-weight: 600;
  line-height: 1;
  color: #121212;
  margin-bottom: 0;
}

.single-pricing .pricing-header .year {
  font-size: 20px;
  font-weight: 500;
  color: var(--primary);
  text-transform: uppercase;
}

.single-pricing .pricing-list ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.single-pricing .pricing-list ul li {
  font-size: 15px;
  line-height: 24px;
  color: #121212;
  padding: 8px 0;
  border-bottom: 1px solid #eee;
}

.single-pricing .pricing-list ul li > img {
  width: 100%;
  height: 160px;
  object-fit: contain;
  margin-bottom: 8px;
}

.single-pricing .pricing-list ul li:nth-last-child(1) {
  border-bottom: 1px solid transparent;
}

.single-pricing .pricing-list ul li i {
  color: #8e0c54;
  margin-right: 8px;
}

.single-pricing .pricing-btn {
  margin-top: 24px;
}

.single-pricing .pricing-btn .main-btn {
  background-color: #fff;
  border-radius: 50px;
  color: #000;
  box-shadow: 0 2px 24px rgba(0, 0, 0, 0.08);
  padding: 16px 24px;
  font-weight: 500;
  text-decoration: none;
  letter-spacing: 0.5px;
}

.single-pricing .buttom-shape {
  position: absolute;
  bottom: -20px;
  left: 0;
  width: 101%;
}

.single-pricing .pricing-header h5 {
  color: #505050;
}

.single-pricing.pro .pricing-header .sub-title::before {
  right: auto;
  left: 0;
  background-color: #8e0c54;
}

.single-pricing.pro .pricing-baloon {
  position: absolute;
  top: 0;
  right: -78px;
  width: 156px;
}

.single-pricing.enterprise .pricing-header .sub-title::before {
  right: 0;
  left: auto;
  background-color: #8e0c54;
}

.single-pricing.enterprise .pricing-flower {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 144px;
}
